<template>
    <div>
      <h2>Logout</h2>
      <p>Logging out...</p>
    </div>
  </template>
  
  <script>
  import UserControl from '../../Apis/usercontrol'
  export default {
    name: 'Logout',
    mounted() {
      // Perform logout actions here, such as clearing authentication token, etc.
      // For example:
      this.logoutUser();
    },
    methods: {
      logoutUser() {
        localStorage.removeItem('user')
        localStorage.setItem('activityCheckActive', 'false');
        UserControl.logout()
        this.$router.push({ name: 'auth1.login' })
      }
    }
  }
  </script>
  