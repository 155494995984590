import Api from './api';

export default {
    async login(data) {
        return await Api.execute('post', 'api/auth/login', data);
    },
    async AdminWhiteList(params) {
        // return await Api.execute('post', 'api/auth/admin_whitelist', data);
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/auth/admin_whitelist'+(queryString ? '?'+queryString : ''));
    },
    async register(data) {
        return await Api.execute('post', 'api/auth/register', data);
    },
    async activity(params) {
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/auth/activity'+(queryString ? '?'+queryString : ''));
    },
    async logout() {
        return await Api.execute('get', 'api/auth/logout');
    },
    async verifiedEmailToken(data) {
        return await Api.execute('post', 'api/auth/verified_email', data);
    },
    async forgetPassword(data){
        return await Api.execute('post', 'api/auth/forget_password', data);
    },
    async resetpassword(data){
        return await Api.execute('post', 'api/auth/reset_password', data);
    },
    async storeUser(data){
        return await Api.execute('post', 'api/user/add', data);
    },
    async listUser(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/user/list'+(queryString ? '?'+queryString : ''));
    },
    async userRelation(data){
        return await Api.execute('post', 'api/user/relation', data);
    },
    async userMultiManager(data){
        return await Api.execute('post', 'api/user/multi_relation', data);
    },
    async userRelationDelete(data){
        return await Api.execute('post', 'api/user/delete_relation', data);
    },
    async getUserWithRelation(){
        return await Api.execute('get', 'api/user/withmanager');
    },
    async listManage(){
        return await Api.execute('get', 'api/user/managers');
    },
    async listAdvertiser(){
        return await Api.execute('get', 'api/user/advertisers');
    },
    async listPublisher(){
        return await Api.execute('get', 'api/user/publishers');
    },
    async listManagerUser(){
        return await Api.execute('get', 'api/user/manager_user');
    },
    async updateUserStatus(data){
        return await Api.execute('post', 'api/user/update_user_status', data);
    },
    async deleteUser(data){
        return await Api.execute('post', 'api/user/delete_user', data);
    },
    async listPubEvents(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/user/list_pub_event'+(queryString ? '?'+queryString : ''));
    },
    async storePubEvents(data){
        return await Api.execute('post', 'api/user/store_pub_event', data);
    },
    async deletePubEvents(id){
        return await Api.execute('delete', 'api/user/delete_pub_event/'+id);
    },
    async updatePubEventsStatus(data){
        return await Api.execute('post', 'api/user/update_pub_event_status', data);
    },
    async updatePostBack(data){
        return await Api.execute('post', 'api/user/update_postback', data);
    },
    async updateToken(data){
        return await Api.execute('post', 'api/user/update_token', data);
    },
    async updateRights(data){
        return await Api.execute('post', 'api/user/update_rights', data);
    },
    async updatePassword(data){
        return await Api.execute('post', 'api/user/update_password', data);
    },
    async getUserDetails(id){
        return await Api.execute('get','api/user/full_details/'+id);
    },
    async updateUserShare(data){
        return await Api.execute('post','api/user/update_user_share', data);
    },
    async updateProfile(data){
        console.log('api_profile',data);
        return await Api.execute('post', 'api/user/update_profile', data);
    }
}