
<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">User Relationship</h4>
          </template>
          <template>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
              <div>Total Rows : {{ totalRows}}</div>
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">
      <b-form-group
        label="Page"
        label-for="per-page-select"
        label-cols-sm="2"
        label-cols-md="2"
        label-cols-lg="2"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </b-col>
              <b-table bordered hover 
              :items="user_rel_filtered" 
              :fields="columns"  
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              >
              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key" >
                  <b-form-input v-model="filters[field.key]"  @blur="handleFilterBlur"></b-form-input>
                </td>
              </template>
                  <template v-slot:cell(username)="data">
                    <span>{{ data.item.username }}</span>
                  </template>
                  <template v-slot:cell(manager_names)="data">
                    <span v-if="!data.item.editable">{{ data.item.manager_names }}</span>
                    <multiselect v-else
            v-model="selected_managers"
            :options="all_managers"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="name"
            track-by="id"
            />
                  </template>
                  <template v-slot:cell(action)="data">
                     <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        <b-col sm="3" md="2" class="my-1" v-if="user_relrows.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        prev-text="Previous"
        next-text="Next"
        first-text="First"
        last-text="Last"
      ></b-pagination>
    </b-col>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import UserControl from '../../Apis/usercontrol'
import Common from '../../Apis/common'
import Multiselect from 'vue-multiselect'
import Loader from '../../components/Loader.vue'
export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index()
  },
  components: {
    'multiselect':Multiselect,Loader
  },
  methods: {
    default () {
      return {
        editable: false
      }
    },
    edit(item) {
      this.selected_managers = item.manager;
      item.editable = true
      this.user_relrows.forEach((element) => {
        if(element.user._id != item.user._id){
          element.editable = false
        }
      });
    },
    async remove(item) {
      if(confirm('Are you sure you want to delete this item?')){
        let response = await UserControl.userRelationDelete({user_id:item.user._id})
        if(response.status == 200){
          this.listUser()
          this.allManager()
        }
      }
    },
    async submit (item) {
      let selectManager = [];
      if(this.selected_managers.length > 0){
        this.selected_managers.forEach((element) => {
          selectManager.push(element.id)
        });
        let new_data ={};
        new_data = {user_id:item.user._id,manager_id:selectManager}
        let store_manger = await UserControl.userMultiManager(new_data);
        this.selected_managers = [];
        this.listUser()
      }
      item.editable = false
    },
    async submitForm(){
      this.loadingData = true;
      try{
        let response = await UserControl.storeUser(this.items)
        if(response.status == 201){
          this.listUser()
          this.allManager()
          this.items = this.default()
          this.$refs.myModalRef.hide();
          let addedUser = response.data.user._id;
          let selectManager = [];
          if(this.selected_managers.length > 0){
            this.selected_managers.forEach((element) => {
              selectManager.push(element.id)
            });
            let new_data ={};
            new_data = {user_id:addedUser,manager_id:selectManager}
            let store_manger = await UserControl.userMultiManager(new_data);
          }
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    handleFilterBlur() {
      this.currentPage = 1; 
        this.user_rel_filtered; 
      this.totalRows = this.user_rel_filtered.length;
      },
    async listUser(){
      this.loadingData = true;
      try{
        let response = await UserControl.getUserWithRelation()
        if(response.status == 200){
          let data = response.data.userWithManager
          
          //add editable and incremental id in each row
          data.forEach((element) => {
            let manager = []
            element.editable = false
            if(element.manager.length > 0){
              element.manager.forEach((man) => {
                manager.push(man.name)
              });
              element.manager_names = manager.join(',')
            }else{
              element.manager_names = 'No Manager set'
            }
            element.username = element.user.int_id + '-' + element.user.username
          });
          this.user_relrows = data  
          this.totalRows = this.user_relrows.length
        }
        this.loadingData = false;
      }catch(error){
        console.log(error)
        this.loadingData = false;
      }
    },
    async allManager(){
      try{
        let response = await UserControl.listManage()
        if(response.status == 200){
          let data = response.data.data
          let options = []
          data.forEach((element) => {
            options.push({name:element.int_id+"-"+element.username,id:element._id})
            });
          this.all_managers = options;
        }
      }catch(error){
        console.log(error)
      }
    },
      async loadData() {
      try {
        await this.listUser()
        await this.allManager()
      } catch (error) {
        console.error('Error loading data:', error);
      }
    }
  },
  data () {
    return {
      columns: [
       { key: 'username', label: 'User', sortable: true },
       { key: 'manager_names', label: 'Manager', sortable: true },
       { key: 'action', label: 'Action' }
      ],
      filters: {
        username: '',
        manager_names: '',  
      },
      user_relrows: [],
      items:{
        username: '',
        // manager: '',
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      selected_managers: [],
      all_managers: [],
      loadingData:true,
    }
  },
  created(){
    this.loadData();
  },
  computed: {
      user_rel_filtered() {
        if (!Array.isArray(this.user_relrows) || this.user_relrows.length === 0) {
          return [];
        }
    const user_rel_filtered = this.user_relrows.filter(item => {
      return Object.keys(this.filters).every(key =>
        String(item[key].toLowerCase()).includes(this.filters[key].toLowerCase())
        // String(item.user[key]).includes(this.filters[key])
      );
    });
    return user_rel_filtered.length > 0
      ? user_rel_filtered
      : [
          Object.keys(this.user_relrows[0]).reduce(function(obj, value) {
            obj[value] = '';
            return obj;
          }, {})
        ];
  }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table-responsive{
  overflow-x:unset !important ;
}
</style>