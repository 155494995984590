<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Postback Logs</h4>
          </template>
          <form class="mt-4" @submit.prevent="submitForm" style="margin-left: 1%;margin-right: 1%;">
            <b-row>
                  <b-col md="5">
            <label for="date_range">Date Range</label>
          <date-range-picker
                      :opens="opens"  
                        :locale-data="localeData"
                        v-model="dateRange"
                        :show-dropdowns="true"
                        :auto-apply="true"  
                        style="width:100%;"
                      >
                </date-range-picker>
              </b-col>
                <b-col md="7">
                  <label for="date_range">User</label>
                <multiselect
                    v-model="items.user"
                    :options="users"
                    :multiple="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
            </b-col>
            <b-col md="7">
                  <label for="date_range">Offers</label>
                <multiselect
                    v-model="items.offer"
                    :options="offers"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
            </b-col>
            <b-col md="5">
              <label for="date_range">Timezone</label>
                <multiselect
                v-model="timezone"
                :options="date_timezone"
                :multiple="false"
                :clear-on-select="false"
                :preserve-search="true"
                label="label"
                track-by="value"
                />
            </b-col>
            </b-row>
            <br/>
            <b-button type="submit" variant="primary">Submit</b-button>
              </form>
              <br/>
              <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
                  <div v-if="rows.length > 0">
                <div style="color: red;">Note: Click on Response or URL to copy</div>
              </div>
              <div>Total Rows : {{ totalRows}} &nbsp;&nbsp;
              Total Scrub : {{ totalScrub}}</div>
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">
      <b-form-group
        label="Page"
        label-for="per-page-select"
        label-cols-sm="2"
        label-cols-md="2"
        label-cols-lg="2"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
           @change="handleFilterBlur"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </b-col>
    <b-table bordered hover 
              :items="rowsData" 
              :fields="columns"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              >
              <template slot="top-row" slot-scope="{ fields }">
                  <td v-for="field in fields" :key="field.key" >
                  <template v-if="field.key === 'user'">
                    <b-form-input v-model="filters[field.key]" @input="filterRows" ></b-form-input>
                  </template>
                  <template v-else-if="field.key === 'offer'">
                    <b-form-input v-model="filters[field.key]" @input="filterRows" ></b-form-input>
                  </template>
                  <template v-else-if="field.key === 'response'">
                    <b-form-input v-model="filters[field.key]" @input="filterRows" ></b-form-input>
                  </template>
                  <template v-else-if="field.key === 'url'">
                    <b-form-input v-model="filters[field.key]" @input="filterRows" ></b-form-input>
                  </template>
                  </td>
                </template>

              <!-- Publisher Column -->
    <template v-slot:cell(publisher)="data">
      <div class="text-left" :title="data.item.user">
        {{ truncateText(data.item.user) }}
      </div>
    </template>

    <!-- Offer Column -->
    <template v-slot:cell(offer)="data">
      <div class="text-left" :title="data.item.offer">
        {{ truncateText(data.item.offer) }}
      </div>
    </template>

    <!-- Response Column -->
    <template v-slot:cell(response)="data">
      <div class="text-left" :title="data.item.response" @click="copyText(data.item.response)">
        {{ truncateText(data.item.response) }}
      </div>
    </template>

    <!-- URL Column -->
    <template v-slot:cell(url)="data">
      <div class="text-left" :title="data.item.url" @click="copyText(data.item.url)">
        {{ truncateText(data.item.url) }}
      </div>
    </template> 
    <template v-slot:cell(postback)="data">
                    <div class="text-left">
                      <b-button v-if="data.item.scrub == 0 && data.item.response == 'SCRUB'" variant="primary" type="button" @click="handlePostBack(data.item)">
                        PostBack
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
        <b-col sm="3" md="2" class="my-1" v-if="rows.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        prev-text="Previous"
        next-text="Next"
        first-text="First"
        last-text="Last"
      ></b-pagination>
    </b-col>
  </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Common from '../../Apis/common'
import UserControl from '../../Apis/usercontrol'
import Offer from '../../Apis/offer'
import DateRangePicker from 'vue2-daterange-picker'
import Multiselect from 'vue-multiselect'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Loader from '../../components/Loader.vue'
export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index(),
    this.listUsers()
    this.listOffers()
    this.getPostbackLog()
  },
  components: {
    DateRangePicker,Multiselect,Loader
  },
  data () {
    return {
      columns: [
        { label: 'Publisher', key: 'user', class: 'text-left', sortable: true },
        { label: 'Offer', key: 'offer', class: 'text-left', sortable: true },
        { label: 'Response', key: 'response', class: 'text-left', sortable: true },
        { label: 'URL', key: 'url', class: 'text-left',sortable:true},
        { label: 'PostBack', key: 'postback',class: 'text-left'}

      ],
      date_timezone:[
      { value: 'Pacific/Pago_Pago' , label: '(GMT-11:00) Pago Pago'},
      { value:  'Pacific/H`onolulu' , label: '(GMT-10:00) Hawaii Time'},
      { value:  'Pacific/Tahiti' , label: '(GMT-10:00) Tahiti'},
      { value:  'America/Anchorage' , label: '(GMT-09:00) Alaska Time'},
      { value:  'America/Los_Angeles' , label: '(GMT-08:00) Pacific Time'},
      { value:  'America/Denver' , label:'(GMT-07:00) Mountain Time'},
      { value:  'America/Chicago' , label:'(GMT-06:00) Central Time'},
      { value:  'America/New_York' , label:'(GMT-05:00) Eastern Time'},
      { value:  'America/Halifax' , label:'(GMT-04:00) Atlantic Time - Halifax'},
      { value:  'America/Argentina/Buenos_Aires' , label:'(GMT-03:00) Buenos Aires'},
      { value:  'America/Sao_Paulo' , label:'(GMT-02:00) Sao Paulo'},
      { value:  'Atlantic/Azores' , label:'(GMT-01:00) Azores'},
      { value:  'GMT' , label:'(GMT+00:00) London'},
      { value:  'Europe/London' , label:'(GMT+01:00) London'},
      { value:  'Europe/Berlin' , label:'(GMT+01:00) Berlin'},
      { value:  'Europe/Helsinki' , label:'(GMT+02:00) Helsinki'},
      { value:  'Europe/Istanbul' , label:'(GMT+03:00) Istanbul'},
      { value:  'Asia/Dubai' , label:'(GMT+04:00) Dubai'},
      { value:  'Asia/Kabul' , label:'(GMT+04:30) Kabul'},
      { value:  'Indian/Maldives' , label:'(GMT+05:00) Maldives'},
      { value:  'Asia/Calcutta' , label:'(GMT+05:30) India Standard Time'},
      { value:  'Asia/Kathmandu' , label:'(GMT+05:45) Kathmandu'},
      { value:  'Asia/Dhaka' , label:'(GMT+06:00) Dhaka'},
      { value:  'Indian/Cocos' , label:'(GMT+06:30) Cocos'},
      { value:  'Asia/Bangkok' , label:'(GMT+07:00) Bangkok'},
      { value:  'Asia/Hong_Kong' , label:'(GMT+08:00) Hong Kong'},
      { value:  'Asia/Pyongyang' , label:'(GMT+08:30) Pyongyang'},
      { value:  'Asia/Tokyo' , label:'(GMT+09:00) Tokyo'},
      { value:  'Australia/Darwin' , label:'(GMT+09:30) Central Time - Darwin'},
      { value:  'Australia/Brisbane' , label:'(GMT+10:00) Eastern Time - Brisbane'},
      { value:  'Australia/Adelaide' , label:'(GMT+10:30) Central Time - Adelaide'},
      { value:  'Australia/Sydney' , label:'(GMT+11:00) Eastern Time - Melbourne}, Sydney'},
      { value:  'Pacific/Nauru' , label:'(GMT+12:00) Nauru'},
      { value:  'Pacific/Auckland' , label:'(GMT+13:00) Auckland'},
      { value:  'Pacific/Kiriti`mati' , label:'(GMT+14:00) Kiritimati'}
      ],

      dateRange:{
        startDate: new Date(),
        endDate: new Date()
      },
      items: {
        user:[],
        offer:[]
      },
      timezone: { value:  'Asia/Calcutta' , label:'(GMT+05:30) India Standard Time'},
      users:[],
      offers:[],
      filters:[],
      rows: [],
      rowsData: [],
      opens: 'right',
      localeData:{
          direction: 'ltr',
          format: 'yyyy/mm/dd',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          firstDay: 0
      },
      totalRows: 0,
      totalScrub:0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      loadingData:true,
    }
  },
  methods: {
    formatDateToYMD(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    truncateText(text, maxLength = 20) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    },
    async copyText(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.$toast.info('Copied Clicked One!', {
                    position: 'top-center'
                })
      } catch($e) {
      }
    },
    async handlePostBack(item){
      this.loadingData = true;
      try {
      let response = await Common.updatePostbackData(item)
      if(response.status == 200){
        const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });
      }
      this.loadingData = false;
      this.submitForm()
    }catch(error){
      this.loadingData = false;
    }
    },
    handleFilterBlur() {
      this.currentPage = 1; // Reset the current page when filtering
        this.submitForm(); // Call listCountry when filter input is blurred
      },
    async submitForm(){
      this.loadingData = true;
      const transformedJson = {
        sdate: this.formatDateToYMD(this.dateRange.startDate),
        edate:this.formatDateToYMD(this.dateRange.endDate),
        user: this.items.user.map(item => item.value),
        offer: this.items.offer.map(item => item.value),
        page: this.currentPage,
        perPage: this.perPage,
        timezone: this.timezone.value
      };
      const data = {
        sdate: this.formatDateToYMD(this.dateRange.startDate),
        edate:this.formatDateToYMD(this.dateRange.endDate),
        user: this.items.user,
        offer: this.items.offer,
      };
      try{
        localStorage.setItem('PostbackLog', JSON.stringify(data))
        let response = await Common.getPostbackData(transformedJson)
        if(response.status == 200){
          this.rows = response.data.data.data
          this.rowsData = response.data.data.data;
          this.totalRows = response.data.data.total
          this.totalScrub = response.data.count
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    filterRows() {  
      this.rowsData = this.rows.filter(row => {
        return Object.keys(this.filters).every(key => {      
            return String(row[key]).toLowerCase().includes(String(this.filters[key]).toLowerCase());
          
        });
      });
      
    },
    async getPostbackLog(){
    const postbacklog = localStorage.getItem('PostbackLog')
    if(postbacklog){
      let postbacklogData = JSON.parse(postbacklog);
     this.dateRange.startDate = new Date(postbacklogData.sdate);
        this.dateRange.endDate = new Date(postbacklogData.edate);
     this.items.user = postbacklogData.user;
     this.items.offer = postbacklogData.offer;

     
    }
  }, 
    async listUsers(){
      this.loadingData = true;
      try{
        let response = await UserControl.listPublisher()
        if(response.status == 200){
          let data = response.data.data
          for(const key in data){
            this.users.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].username})
          }
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async listOffers(){
      this.loadingData = true;
      try{
        let response = await Offer.listActiveOffer()
        if(response.status == 200){
          let data = response.data.data
          for(const key in data){
            this.offers.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].name})
          }
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'submitForm',
  },
  computed: {
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.filter:focus-within .multiselect__content-wrapper {
    width: 300px!important;
}
</style>