<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <loader :isLoading="loadingData" />
            <template v-slot:headerTitle>
              <h4 class="card-title">Site Logs</h4>
            </template>
            <form class="mt-4" @submit.prevent="submitForm" style="margin-left: 1%;margin-right: 1%;">
              <b-row>
                    <b-col md="3">
              <label for="date_range">Date Range</label>
            <date-range-picker
                        :opens="opens"  
                          :locale-data="localeData"
                          v-model="dateRange"
                          :show-dropdowns="true" 
                          :auto-apply="true" 
                          style="width:100%;"
                        >
                  </date-range-picker>
                </b-col>
                <b-col md="3">
                   <b-form-group label="Extra String:" label-for="extra-string">
                     <b-form-textarea id="extra-string" v-model="items.data" rows="3"></b-form-textarea>
                   </b-form-group>
                </b-col>

              <b-col md="2">
                    <label for="">Collection : </label>
                    <textarea type="text" v-model="items.collection" style="border: 1px solid #cccccc; border-radius: 4px; 
                     padding: 8px; width: calc(100% - 18px); height: 45px; font-size: 14px; resize: none;"/>
              </b-col>
              <b-col md="2">
                   <b-form-group label="Limit:" label-for="extra-string">
                    <b-form-input type="text" v-model="limit"></b-form-input>
                   </b-form-group>
                </b-col>
        <b-col md="2" style="margin-top: 40px;">
                  <label>
                    <input type="checkbox" v-model="isChecked"> <b>Get Count</b>
                  </label>
              </b-col>
              <b-col md="3">
                <label for="date_range">Timezone</label>
                <multiselect
                v-model="timezone"
                :options="date_timezone"
                :multiple="false"
                :clear-on-select="false"
                :preserve-search="true"
                label="label"
                track-by="value"
                />
              </b-col>
              </b-row>
              <b-button  style="margin-top: 20px;"type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
              <b-form-textarea style="margin-top: 20px;"
                v-if="responseData === 0 || responseData"
                   rows="100"
                   id="response_data"
                   v-model="formattedResponseData"
                   readonly
              ></b-form-textarea>

                </form>
                <br/>
            <template>
    </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
    
  </template>
  <script>
  import { sofbox } from '../../config/pluginInit'
  import Common from '../../Apis/common'
  import DateRangePicker from 'vue2-daterange-picker'
  import Multiselect from 'vue-multiselect'
  //you need to import the CSS manually
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import Loader from '../../components/Loader.vue'

  export default {
    name: 'UiDataTable',
    mounted () {
      sofbox.index()
    },
    components: {
      DateRangePicker,Multiselect,Loader
    },
    data () {
      return {
        dateRange:{
          startDate: new Date(),
          endDate: new Date()
        },
        items: {
         
        },
        rows: [],
        opens: 'right',
        localeData:{
            direction: 'ltr',
            format: 'yyyy/mm/dd',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            firstDay: 0
        },
        date_timezone:[
      { value: 'Pacific/Pago_Pago' , label: '(GMT-11:00) Pago Pago'},
      { value:  'Pacific/H`onolulu' , label: '(GMT-10:00) Hawaii Time'},
      { value:  'Pacific/Tahiti' , label: '(GMT-10:00) Tahiti'},
      { value:  'America/Anchorage' , label: '(GMT-09:00) Alaska Time'},
      { value:  'America/Los_Angeles' , label: '(GMT-08:00) Pacific Time'},
      { value:  'America/Denver' , label:'(GMT-07:00) Mountain Time'},
      { value:  'America/Chicago' , label:'(GMT-06:00) Central Time'},
      { value:  'America/New_York' , label:'(GMT-05:00) Eastern Time'},
      { value:  'America/Halifax' , label:'(GMT-04:00) Atlantic Time - Halifax'},
      { value:  'America/Argentina/Buenos_Aires' , label:'(GMT-03:00) Buenos Aires'},
      { value:  'America/Sao_Paulo' , label:'(GMT-02:00) Sao Paulo'},
      { value:  'Atlantic/Azores' , label:'(GMT-01:00) Azores'},
      { value:  'GMT' , label:'(GMT+00:00) London'},
      { value:  'Europe/London' , label:'(GMT+01:00) London'},
      { value:  'Europe/Berlin' , label:'(GMT+01:00) Berlin'},
      { value:  'Europe/Helsinki' , label:'(GMT+02:00) Helsinki'},
      { value:  'Europe/Istanbul' , label:'(GMT+03:00) Istanbul'},
      { value:  'Asia/Dubai' , label:'(GMT+04:00) Dubai'},
      { value:  'Asia/Kabul' , label:'(GMT+04:30) Kabul'},
      { value:  'Indian/Maldives' , label:'(GMT+05:00) Maldives'},
      { value:  'Asia/Calcutta' , label:'(GMT+05:30) India Standard Time'},
      { value:  'Asia/Kathmandu' , label:'(GMT+05:45) Kathmandu'},
      { value:  'Asia/Dhaka' , label:'(GMT+06:00) Dhaka'},
      { value:  'Indian/Cocos' , label:'(GMT+06:30) Cocos'},
      { value:  'Asia/Bangkok' , label:'(GMT+07:00) Bangkok'},
      { value:  'Asia/Hong_Kong' , label:'(GMT+08:00) Hong Kong'},
      { value:  'Asia/Pyongyang' , label:'(GMT+08:30) Pyongyang'},
      { value:  'Asia/Tokyo' , label:'(GMT+09:00) Tokyo'},
      { value:  'Australia/Darwin' , label:'(GMT+09:30) Central Time - Darwin'},
      { value:  'Australia/Brisbane' , label:'(GMT+10:00) Eastern Time - Brisbane'},
      { value:  'Australia/Adelaide' , label:'(GMT+10:30) Central Time - Adelaide'},
      { value:  'Australia/Sydney' , label:'(GMT+11:00) Eastern Time - Melbourne}, Sydney'},
      { value:  'Pacific/Nauru' , label:'(GMT+12:00) Nauru'},
      { value:  'Pacific/Auckland' , label:'(GMT+13:00) Auckland'},
      { value:  'Pacific/Kiriti`mati' , label:'(GMT+14:00) Kiritimati'}
      ],

        timezone: { value:  'Asia/Calcutta' , label:'(GMT+05:30) India Standard Time'},
        responseData:null,
        totalRows: 0,
        currentPage: 1,
        limit: '',
        loadingData:false,
        isChecked: false
      }
    },
    methods: {
      formatDateToYMD(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      async submitForm(){
        // this.loadingData = true;
        const transformedJson = {
          sdate: this.formatDateToYMD(this.dateRange.startDate),
          edate:this.formatDateToYMD(this.dateRange.endDate),
          collection : this.items.collection.toLowerCase(),
          limit: this.limit,
          checked: this.isChecked,
          timezone: this.timezone.value
        };
        try{
          let response = await Common.getSiteLog(transformedJson,this.items.data)
          if(response.status == 200){
            this.loadingData = false;
            this.responseData = response.data.data
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
          this.loadingData = false;
        }
      },
    },
    watch: {
     // Watch for changes in currentPage and fetchData accordingly
     currentPage: 'submitForm',
    },
    computed: {
     formattedResponseData() {
        if(typeof this.responseData === 'number'){
            return `Total Count is : ${this.responseData}`;
        }
        if (Array.isArray(this.responseData.data)) {
        return this.responseData.data.map((item, index) => {
          const id = item._id?.$oid || "N/A";
          const timestamp = item.updated_at?.$date?.$numberLong || "N/A"; // Use appropriate date field

          // Create a formatted string for the dynamic fields
          const itemDetails = Object.keys(item).map(key => {
            if (key === '_id') {
              return `    [${key}] => MongoId Object\n    (\n        [$id] => ${id}\n    )`;
            }
            if (key === 'updated_at' || key === 'created_at') {
              return `    [${key}] => MongoDate Object\n    (\n        [sec] => ${new Date(parseInt(timestamp)).getTime() / 1000}\n    )`;
            }
            return `    [${key}] => ${JSON.stringify(item[key])}`;
          }).join('\n');

          return `Num: ${index + 1} Time: ${new Date(parseInt(timestamp)).toISOString()}\n` + 
            `Array\n(\n` +
            `${itemDetails}\n` +
            `)\n`;
        }).join('\n');
      }
        return JSON.stringify(this.responseData.data, null, 2);
        },
    allFieldsValid() {
        return this.items.collection;
      },
    }
  }
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style>
  .filter:focus-within .multiselect__content-wrapper {
      width: 300px!important;
  }
  </style>