<template>
    <b-modal ref="myModalRef" id="modal-4" size="xl" hide-footer>
              <form class="mt-4" @submit.prevent="submitForm" enctype="multipart/form-data">
                <b-row>
                  <b-col md="6">
                <b-form-group>
                  <label for="name">Name</label><span class="text-danger"> *</span>
                  <b-form-input id="name" type="text" placeholder="Enter Name" v-model="items.name"></b-form-input>
                  <span v-if="allerros.name" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                </b-form-group>
                <b-form-group>
                  <label for="url">Url</label><span class="text-danger"> *</span><a @click="showMacros()">Add Macros</a>
                  <div  v-if="showMacro">
                    <small v-for="(value, index) in macros" :key="index">
                        <label class="p-1" @click="addMacros(value)" style="background:#e6e6e6;margin-right:5px;">{{value}}</label>
                    </small>
                  </div>
                  <b-form-textarea id="url" rows="4" placeholder="Url" v-model="items.url"></b-form-textarea>
                  <span v-if="allerros.url" :class="['label label-danger']">@{{ allerros.url[0] }}</span>
                </b-form-group>
                <b-form-group>
                  <label for="price">Price</label><span class="text-danger"> *</span>
                  <b-form-input id="price" type="text" placeholder="Enter Price" v-model="items.price"></b-form-input>
                  <span v-if="allerros.price" :class="['label label-danger']">@{{ allerros.price[0] }}</span>
                </b-form-group>
                <b-form-group label="Description" label-for="description">
                  <b-form-textarea id="description" rows="4" placeholder="Description" v-model="items.description"></b-form-textarea>
                  <span v-if="allerros.description" :class="['label label-danger']">@{{ allerros.description[0] }}</span>
                </b-form-group>
                <b-form-group>
                <label for="customFile">File</label> &nbsp;<a v-if="items.image" :href="items.image" target="_blank">Already Uploaded file</a>
                  <b-form-file
                    placeholder="Choose a file"
                    id="customFile"
                    @change="onFileChange($event)"
                  ></b-form-file>
              </b-form-group>
              <b-form-group v-if="isAdmin">
                <label for="usertype">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.active" :options="active_status" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group> 
                </b-col>
                <b-col md="6">
              <b-form-group>
                <label for="usertype">Device type</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.device_type" class='form-control' :options="deviceType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Device</b-form-select-option>
                        </template>
              </b-form-select>
              <span v-if="allerros.device_type" :class="['label label-danger']">@{{ allerros.device_type[0] }}</span>
              </b-form-group> 
                <b-form-group>
                  <label for="caps">Caps</label><span class="text-danger"> *</span>
                  <b-form-input id="cap" type="text" placeholder="Caps" v-model="items.cap"></b-form-input>
                  <span v-if="allerros.cap" :class="['label label-danger']">@{{ allerros.cap[0] }}</span>
                </b-form-group>
                <b-form-group>
                <label for="usertype">Conversion Flow</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.conv_flow_id" :options="convFlow" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Flow</b-form-select-option>
                        </template>
              </b-form-select>
              <span v-if="allerros.conv_flow_id" :class="['label label-danger']">@{{ allerros.conv_flow_id[0] }}</span>
              </b-form-group> 
              <b-form-group>
                <label for="usertype">Pricing Model</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.pricing_model" :options="pricingModels" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              <span v-if="allerros.pricing_model" :class="['label label-danger']">@{{ allerros.pricing_model[0] }}</span>
              </b-form-group> 
              <b-form-group>
              <label for="usertype">Offer Type</label><span class="text-danger"> </span>
                      <b-form-select plain v-model="items.type" :options="offerType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Type</b-form-select-option>
                        </template>
              </b-form-select>
              <span v-if="allerros.pricing_model" :class="['label label-danger']">@{{ allerros.pricing_model[0] }}</span>
              </b-form-group> 
              <b-form-group v-if="isPrivate">
                <label for="usertype">Blocking Type</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.blocking_type" :options="blockingType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Blocking Type</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group>
              <b-form-group label="Notes" label-for="note">
                  <b-form-textarea id="note" rows="4" placeholder="Notes" v-model="items.note"></b-form-textarea>
                  <span v-if="allerros.note" :class="['label label-danger']">@{{ allerros.note[0] }}</span>
                </b-form-group>
                <b-form-group>
                  <label for="expired_at">Expired_at</label><span class="text-danger"> *</span>
                  <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="items.expire_date"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col md="12"><p style="float: right;cursor: pointer;" @click="openOther" v-if="showMore == false">Offer Target</p></b-col>
              </b-row>
              <b-row v-if="showMore">
                <b-col md="12">
                <template>
              <b-row style="justify-content: center;">
              <label for="T1">Counties:</label>
              <b-col md="5">
               <b-form-group>
                <label for="status">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.country_status" :options="TargetType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group>
               </b-col>
               <b-col md="5">
                <b-form-group>
                  <label for="country">Country</label>
                <multiselect
                    v-model="offer_data.country"
                    :options="list"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
              </b-form-group>
              </b-col>
            </b-row>
              </template>
            </b-col>
            <b-col md="12">
             <template>
                <b-row style="justify-content: center;">
                <label for="T1">Carrier:</label>
                <b-col md="5">
                <b-form-group>
                <label for="status">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.carrier_status" :options="TargetType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group>
               </b-col>

               <b-col md="5">
                <b-form-group>
                  <label for="country">Carrier</label>
                 <multiselect
                    v-model="offer_data.carrier"
                    :options="CarrierNameList"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
              </b-form-group>
              </b-col>
              </b-row>
              </template>
              </b-col>

              <b-col md="12">
              <template>
                <b-row style="justify-content: center;">
                <label for="T1">OS:</label>
                <b-col md="5">
                <b-form-group>
                <label for="status">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.os_status" :options="TargetType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="5">
                <b-form-group>
                  <label for="os">OS</label>
              <multiselect
                    v-model="offer_data.os"
                    :options="OSList"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
              </b-form-group>
              </b-col>
            </b-row>
            <b-col md="12"><p style="float: right;cursor: pointer;" @click="openOther" v-if="showMore == true">show less</p></b-col>
              </template>
              </b-col>
              </b-row>
                <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
              </form>
            </b-modal>
</template>
<script>
import { BIconPaperclip } from 'bootstrap-vue';
import Offer from '../../Apis/offer'
import Multiselect from 'vue-multiselect'
  export default {
    name: 'Modal',
   data : function(){
    return{
      macros:['sub1','sub2', 'sub3','sub4','sub5', 'sub6','sub7','sub8', 'sub9','sub10','sub11', 'sub12','clickid','subid'],
      showMacro:false,
      showMore:false,
      OfferTarget: [],
      offer_data:{
        country: '',
        os:'',
        carrier:''
      }
    }
   },
   components: {
      Multiselect
    },
   mounted() {
    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.close() 
      }
    })
  },
    methods: {
      close() {
        this.$emit('close');
      },
      submitForm() {
        this.items.country = this.offer_data.country;
        this.items.os = this.offer_data.os;
        this.items.carrier = this.offer_data.carrier;
        this.$emit('submitForm');
      },
      openOther(){
        this.showMore = !this.showMore;
      },
      addMacros(data){
        const index = this.macros.indexOf(data);
        var str = "{" + data + "}";
        if(this.items.url == null){
          this.items.url = str;
          if (index > -1) {
            this.macros.splice(index, 1);
          }
        }else{
          if(!this.items.url.match(new RegExp(str))){    
              this.items.url = this.items.url + str;
              if (index > -1) {
                this.macros.splice(index, 1);
              }
          } 
        }
        },
        onFileChange(event) {
            this.items.file = event.target.files[0];
        },
        showMacros(){
          console.log('items',this.items);
          this.showMacro = !this.showMacro;
        },
  async listOfferTarget(){
    try {
    const response = await Offer.getOfferTarget({offer_id:this.items._id});
      if (response.status === 200) {
                  this.OfferTarget = response.data.data;
  
                  // Access properties directly
                 this.items.country_status = this.OfferTarget.country_status;
                 this.items.carrier_status = this.OfferTarget.carrier_status;
                 this.items.os_status = this.OfferTarget.os_status;
  
                 // Convert country, carrier, and os arrays to desired format
                this.offer_data.country = this.OfferTarget.country.map(t => ({ value: t, text: t }));
                this.offer_data.carrier = this.OfferTarget.carrier.map(t => ({ value: t, text: t }));
                this.offer_data.os = this.OfferTarget.os.map(t => ({ value: t, text: t }));
                this.allErrors = response.data.errors;
          }
          if (response.status === 201) {
                this.offer_data.os = [];
                this.offer_data.carrier = [];
                this.offer_data.country = [];
                this.items.os_status = '';
                this.items.carrier_status = '';
                this.items.country_status = '';
          }
    
  } catch (error) {
    console.error('An error occurred:', error);
  }

      },
      show() {
        this.listOfferTarget();
      this.$refs.myModalRef.show();
      },
      hide() {
        this.$refs.myModalRef.hide();
      }
    },
     props: ["items","allerros","deviceType","active_status","convFlow","pricingModels","isManager","isAdmin","isAdvertiser","offerType", "blockingType", "TargetType","list","OSList", "CarrierNameList"],
     computed:{
      allFieldsValid() {
          return this.items.name;
      },
      isPrivate(){
        if(this.items.type != null){
          return this.items.type === this.offerType.find(option =>option.text === 'Private').value;
        }
      },
     }
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>  
<style>
label[for="T1"] {
  font-weight: bold;
}
</style>
