import Api from './api';

export default {
    async listConvFlow(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/list_convflow'+(queryString ? '?'+queryString : ''));
    },
    async storeConvFlow(data){
        return await Api.execute('post', 'api/offer/store_convflow', data);
    },
    async activeConvFlow(){
        return await Api.execute('get', 'api/offer/active_convflow');
    },
    async deleteConvFlow(id){
        return await Api.execute('delete', 'api/offer/delete_convflow/'+id);
    },
    async updateConvFlowStatus(data){
        return await Api.execute('post', 'api/offer/update_convflow_status', data);
    },
    async listOffer(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/list'+(queryString ? '?'+queryString : ''));
    },
    async storeOffer(data){
        return await Api.execute('post', 'api/offer/add', data, {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/x-www-form-urlencoded'
        });
    },
    async cloneOffer($data){
        return await Api.execute('post', 'api/offer/clone', $data);
    },
    async listUnapprovedOffer(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/list_unapproved'+(queryString ? '?'+queryString : ''));
    },
    async listExpiredOffer(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/list_expired'+(queryString ? '?'+queryString : ''));
    },
    async listActiveOffer(){
        return await Api.execute('get', 'api/offer/list_active');
    },
    async listPrivateOffer(){
        return await Api.execute('get', 'api/offer/list_private');
    },
    async listUserCap(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/list_user_cap'+(queryString ? '?'+queryString : ''));
    },
    async storeUserCap(data){
        return await Api.execute('post', 'api/offer/store_user_cap', data);
    },
    async deleteUserCap(id){
        return await Api.execute('delete', 'api/offer/delete_user_cap/'+id);
    },
    async listUserOffer(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/list_user_offer'+(queryString ? '?'+queryString : ''));
    },
    async storeUserOffer(data){
        return await Api.execute('post', 'api/offer/store_user_offer', data);
    },
    async deleteUserOffer(id){
        return await Api.execute('delete', 'api/offer/delete_user_offer/'+id);
    },
    async updateUserOfferStatus(data){
        return await Api.execute('post', 'api/offer/update_user_offer_status', data);
    },
    async storeUserShare(data){
        return await Api.execute('post', 'api/offer/store_user_share', data);
    },
    async listUserShare(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/list_user_share'+(queryString ? '?'+queryString : ''));
    },
    async deleteUserShare(id){
        return await Api.execute('delete', 'api/offer/delete_user_share/'+id);
    },
    async listUserOfferPayout(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/list_user_payout'+(queryString ? '?'+queryString : ''));
    },
    async storeUserOfferPayout(data){
        return await Api.execute('post', 'api/offer/store_user_payout', data);
    },
    async deleteUserOfferPayout(id){
        return await Api.execute('delete', 'api/offer/delete_user_payout/'+id);
    },
    async deleteOffer(data){
        return await Api.execute('post', 'api/offer/delete_offer', data);
    },
    async deleteAllOffer(data){
        return await Api.execute('post', 'api/offer/delete_all_offer', data);
    },
    async updateOfferStatus(data){
        return await Api.execute('post', 'api/offer/update_offer_status', data);
    },
    async singleOffer(data){
        return await Api.execute('post', 'api/offer/single_offer', data);
    },
    async storeImageOffer(data){
        return await Api.execute('post', 'api/offer/upload_offer_data', data, {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/x-www-form-urlencoded'
        });
    },
    async removeImages(data){
        return await Api.execute('delete', 'api/offer/remove_image', data);
    },
    async getData(data){
        return await Api.execute('post', 'api/offer/get_data', data);
    },
    async storeNote(data){
        return await Api.execute('post', 'api/offer/note_store', data);
    },
    async storeOfferTarget(data){
        return await Api.execute('post', 'api/offer/offer_targeting', data);
    },
    async getOfferTarget(data){
        return await Api.execute('post', 'api/offer/targeting_offer', data);
    },
    
    async listNotesByUserId(data){
        return await Api.execute('post', 'api/offer/note_list', data);
    },
    async updatePayoutStatus(data){
        return await Api.execute('post','api/offer/update_payout_status', data);
    },
    async listGroupOffer(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/list_offer_group/'+(queryString ? '?'+queryString : ''));
    },
    async storeGroupOffer(data){
        return await Api.execute('post', 'api/offer/store_offer_group', data);
    },
    async deleteGroupOffer(id){
        return await Api.execute('delete', 'api/offer/delete_offer_group/'+id);
    },
    async updateGroupOfferStatus(data){
        return await Api.execute('post', 'api/offer/update_offer_group_status', data);
    },
    async listOfferGroupOffer(id){
        return await Api.execute('get', 'api/offer/list_offer_group_offer/'+id);
    },
    async storeOfferGroupOffer(data){
        return await Api.execute('post', 'api/offer/store_offer_group_offer', data);
    },
    async deleteOfferGroupOffer(id){
        return await Api.execute('delete', 'api/offer/delete_offer_group_offer/'+id);
    },
    async updateStatusByStatus(data){
        return await Api.execute('post', 'api/offer/update_status', data);
    },
    async updateAllStatusByStatus(data){
        return await Api.execute('post', 'api/offer/update_all_status', data);
    },
    async listPubCountry(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/list_pub_country'+(queryString ? '?'+queryString : ''));
    },
    async storePubCountry(data){
        return await Api.execute('post', 'api/offer/store_pub_country', data);
    },
    async deletePubCountry(id){
        return await Api.execute('delete', 'api/offer/delete_pub_country/'+id);
    },
    async updatePubCountryStatus(data){
        return await Api.execute('post', 'api/offer/update_pub_country_status', data);
    },
    async storeDomainOffer(data){
        return await Api.execute('post', 'api/offer/upload_domain', data);
    },
    async storeAgency(data){
        return await Api.execute('post', 'api/offer/add_agency', data);
    },
    async listDomain(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/domain_list'+(queryString ? '?'+queryString : ''));
    },
    async deleteDomain(data){
        return await Api.execute('delete', 'api/offer/delete_domain',data);
    },
    async updateDomainStatus(data){
        return await Api.execute('post', 'api/offer/update_domain_status', data);
    },
    async updateAgencyStatus(data){
        return await Api.execute('post', 'api/offer/update_agency_status', data);
    },
    async listAgency(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/offer/agency_list'+(queryString ? '?'+queryString : ''));
    },
    async deleteAgency(data){
        return await Api.execute('delete', 'api/offer/delete_agency',data);
    },

}