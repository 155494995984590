export default {
  displayNavTicket (state, data) {
    state.navTicket = data
  },
  displaySidebarTicket (state, data) {
    state.sidebarTicket = data
  },
  updateNumberOfTicket (state, data) {
    state.numberOfTicket = data
  }
}
